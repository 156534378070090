
import { defineComponent } from 'vue';
import {IApiResponse_certificationLogin, IApiResponse_preorder} from "@/global/api"
import {useStore} from "vuex"
import {key} from "@/store"

export default defineComponent({
  name: 'PreOrder',

  components: {
  },

  data() {
    return {
      firstname: "",
      name: "",
      email: "",
      country: "",
      phoneNumber: "",
      message: "",

      status:  'show form' as 'show form' | 'sending data' | 'show response',

      responseMassage: null as string | null,

      store: useStore(key),
    }
  },

  computed: {
    preorderData(): IApiResponse_preorder {
      return {
        titleName:      this.store.state.preorderData?.titleName      || '',
        description:    this.store.state.preorderData?.description    || '',
        image:          this.store.state.preorderData?.image          || null,
      }
    }
  },

  methods: {

    async formSubmit(e: Event) {
      e.preventDefault()

      this.status = "sending data"

      const contactUrl = new URL('https://api.commergnat.com/contact?')

      contactUrl.searchParams.append('firstname', this.firstname)
      contactUrl.searchParams.append('name',      this.name)
      contactUrl.searchParams.append('email',     this.email)
      contactUrl.searchParams.append('country',   this.country)
      contactUrl.searchParams.append('phone',     this.phoneNumber)
      contactUrl.searchParams.append('message',   this.message)

      try {
        const response = await fetch(
            contactUrl.href,
            {
              method: 'POST',
            })

        const dataRespon = await response.json()

        window.setTimeout(() => {
          this.responseMassage = dataRespon.succes
          this.status           = 'show response'
        }, 5000)


      } catch {
        window.setTimeout(() => {
          this.responseMassage  = 'Erreur de connection, réesséyez…'
          this.status           = 'show response'
        }, 5000)
      }

    }

  },

});
